@use "../../scss/" as *;

.movie-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 3rem;

    @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    &__loadmore {
        text-align: center;
    }
}

.movie-search {
    position: relative;
    width: 100%;
    max-width: 500px;

    input {
        width: 100%;
        padding-right: 8rem;
    }

    button {
        position: absolute;
        right: 0;
        top: 2px;

        @include mobile {
            right: 1px;
            top: 1px;
        }
    }
}
.movie-search__suggestions {
    background: #000000;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.movie-search__suggestions ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.movie-search__suggestions li {
    padding: 10px;
    cursor: pointer;
}

.movie-search__suggestions li:hover {
    background: #000000;
}
